import React from "react"

export default function FourOhFour() {
  return (
    <div style={{ textAlign: `center` }}>
      <br />
      <h1>404</h1>
      <br />
      <h1>This page doesn't exist. Bummer!</h1>
    </div>
  )
}
